@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

.login-content.is-loading {
    position: relative;
}

body{
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

h1{
    color: var(--vud-neutral-dark-90) !important;
}

.login-box{
    padding: 3rem 6rem 3rem 6rem !important;
}

.required-field label:before {
    position: absolute;
    content: "*";
    margin-left: -0.438rem;
    top: 0.313rem;

    color: var(--input-error-text-color);
    font-size: 1rem;
}

.error-text{
    color: var(--input-error-text-color);
}

input.form-control, select.form-control{
    border-color: var(--vud-neutral-light-90) !important;
    background-color: var(--vud-neutral-light-90) !important;
    box-shadow: 0 0.2rem 0.4rem 0 var(--vud-neutral-light-90) !important;
}